import * as AT from '../../action-types';
import { get } from 'lodash';
import utils from '../../../utils/utils';
import createMiddleware from '../../utils/middleware-helper';

import { rxEventTypes } from '../../core/sdk/sdk.enums';
import { getMessageObject } from '../../utils';
import { SupportedPatientTypes } from '../../../utils/enums';
import { sdkSendMessage } from '../../core/sdk/sdk.actions';
import { setPatients, updateHostPatient, setConflictedPatient, savePatient, getPatients } from './patient-search.actions';

const featureName = AT.PATIENT_SEARCH;

export const goThroughOverride = ({ dispatch, getState, action }) => {
  const { type } = action;  

  switch (type) {
    case AT.APP_READY: {
      const state = getState();
      const { companyId, isInlineMode } = state.shell.fromUrl;
    
      if (!isInlineMode) {
        dispatch(getPatients({ patientTypes: SupportedPatientTypes, setLoader: true, companyId }));
      }
      break;
    }

    default:
    //do nothing
  }
}

export const middleware = ({ dispatch, getState, action }) => {
  const { type, payload } = action;
  switch (type) {
    case AT.GET_PATIENTS.SUCCESS: {
      const patients = get(payload, 'data.patients', []) || [];
      const modifiedPatients = patients.map(patient => ({
        ...patient,
        dateOfBirth: utils.formatDateString(patient.dateOfBirth)
      }));
      dispatch(setPatients(modifiedPatients));
      break;
    }

    case AT.GET_PATIENTS.ERROR: {
      break;
    }

    case AT.USER_CLICKED_ON_SELECT_PATIENT: {
      const { patient } = payload;
      // const arr = [];
      // if (patient.type === 3) {
      //   // If type is 3 the meaning is that the patient is already linked,
      //   // so no need to check conflicts anymore.
      //   arr.push(userClickedOnAnAlreadyPairedPatient({ patient }));
      // } else {
      //   arr.push(checkIfPatientHasConflictsEdit({ patient, companyId }));
      // }

      // dispatch(arr);

      // IMPORTANT:
      // Since in the close release we will not handle IDS users,
      // we work with only ITR patients and whenever the doctor select a patient
      // we immidiatly transfer it to the RX.  the code above is in remark because we
      // will use it in the next release. (ITEROBIZ-39294)
      dispatch([updateHostPatient(patient)]);
      break;
    }

    case AT.UPDATE_HOST_PATIENT: {
      const message = getMessageObject();
      message.data = {
        eventId: rxEventTypes.patientAppSelectedPatient,
        data: payload
      };
      dispatch([sdkSendMessage(message)]);
      break;
    }

    case AT.USER_DECIDE_TO_PAIR_PATIENT: {
      const { patient, companyId } = payload;
      dispatch([setConflictedPatient(null), savePatient({ patient, companyId, setLoader: true })]);
      break;
    }

    case AT.SAVE_PATIENT.SUCCESS: {
      // TBD after enabling IDS and similare behaviour to
      // const patientResponse = payload.data;
      // if (patientResponse.statusCode === ApiStatusCodes.CREATED) {
      //   dispatch(updateHostPatient(patientResponse.patients && patientResponse.patients[0]));
      // }
      break;
    }
    case AT.SAVE_PATIENT.ERROR: {
      // TBD after enabling IDS and similare behaviour to
      // patient management form
      break;
    }

    default:
  }
};


export default createMiddleware({
  feature: featureName,
  goThroughOverride
})(middleware);
