import { sdkRawMessage } from './../core/sdk/sdk.enums';
import {IMask} from "react-imask";
export const getMessageObject = () => ({
  ...sdkRawMessage,
  data: {
    eventId: null,
    data: null
  }
});

export const isValidDateFormat = (format) => {
  if (!format) { return false; };
  const lowerCaseFormat = format.toLowerCase();
  return lowerCaseFormat.includes('yyyy') || lowerCaseFormat.includes('mm') || lowerCaseFormat.includes('dd');
}
export const mapDateOfBirthToDateInputMask = format => {
  return isValidDateFormat(format) ? format.replace(/\w/g, '0') : '0000/00/00';
};

export const getMaskedValue = (format, value) => {
  const mask = mapDateOfBirthToDateInputMask(format);
  const maskPipe = IMask.createPipe({ mask: mask });
  return maskPipe(value)
};


