import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import TranslatedContent from '../../Translations/TranslatedContent';
import {getMaskedValue, isValidDateFormat} from "../../../redux/utils";

export const handleChange = (e,  onChange, ref) => {
    onChange(e);
};

function DateInput(props) {
    const { id, name, value, readOnly, onChange, touched, onFocus, format, inputLabel, error, forceShowError, forceDateOfBirthChange } = props;
    const [inputValue, setValue] = useState('');

    useEffect(() => {
        if(!touched){
            setValue(value ? value : '');
        }
    }, [value]);

    const parsedFormat = isValidDateFormat(format) ? format : 'YYYY/MM/DD';
    const handleValueChange = (e) =>{
        const valueToChange = e.target.value
        const maskedValue = getMaskedValue(format,  e.target.value)
        const pointer = e.target.selectionStart;
        const element = e.target;
        onChange(e)
        /*
          chromium versions 109-119 have a bug with the Korean lang virtual keyboard and Chinese lang
          https://jira.aligntech.com/browse/TR-7660
          input event executes twice for these languages
          if a mask applies between these input events, the last input is duplicating
         */
        requestAnimationFrame(()=>{
            setValue(maskedValue);
            //we need to update masked value in redux form
            forceDateOfBirthChange(maskedValue);

            //position of cursor is broken because of timeout. Let's fix it
            const newCursorPosition = valueToChange.length < maskedValue.length ? pointer+1 : pointer;
            element.selectionStart = newCursorPosition;
            element.selectionEnd = newCursorPosition;
        });
    };


    const isError = (!!touched && !!error) || forceShowError;
    const label = inputLabel ? <TranslatedContent contentArray={inputLabel} /> : '';
    const errorMessage = isError ? <TranslatedContent contentArray={error} /> : '';


    return (
        <TextField
            type={'tel'}
            autoComplete={'off'}
            disabled={readOnly}
            id={id}
            name={name}
            error={isError}
            label={label}
            helperText={errorMessage}
            fullWidth
            placeholder={parsedFormat}
            value={ inputValue ? inputValue : '' }
            onFocus={onFocus}
            onChange={handleValueChange}
        />
    );
}

DateInput.propTypes = {
    value: PropTypes.any,
    id: PropTypes.string,
    name: PropTypes.string,
    inputLabel: PropTypes.any,
    format: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    forceShowError: PropTypes.bool,
    touched: PropTypes.bool,
    readOnly: PropTypes.bool
};

export default DateInput;
