import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { debounce } from 'lodash';
import classNames from 'classnames';
import WarningPopup from '../UI/WarningPopup';
import { patientFormTranslatedStrings as messages } from '../../translations/PatientFormTranslations';
import {
  userClickedOnConfirmErrorGeneralPopup,
  userClickedOnCancelPatientForm,
  userClickedOnSubmitPatientForm,
  userChangedInputPatient
} from '../../redux/feature/patient-management/patient-management.actions';
import TranslatedContent from '../Translations/TranslatedContent';
import DynamicInputField from '../DynamicInputField/DynamicInputField';
import Alert from '../UI/Alert';
import utils from '../../utils/utils';
import Header from '../UI/Header';
import Footer from '../UI/Footer';
import styles from './PatientManagementForm.module.scss';
import { FieldName } from '../../utils/enums';
import { fullDateOfBirthMask } from '../../consts'
import BaseButton from '../UI/BaseButton';

class PatientForm extends React.Component {
  static propTypes = {
    /**
     * https://redux-form.com/8.2.2/docs/api/props.md/#-code-submitting-boolean-code-
     */
    submitting: PropTypes.bool.isRequired,
    /**
     * https://redux-form.com/8.2.2/docs/api/props.md/#-code-invalid-boolean-code-
     */
    invalid: PropTypes.bool.isRequired,
    /**
     * https://redux-form.com/8.2.2/docs/api/props.md/#-code-invalid-boolean-code-
     */
    patientId: PropTypes.string,
    companyId: PropTypes.string,
    patientType: PropTypes.string,
    config: PropTypes.object,
    initialValues: PropTypes.object,
    formValues: PropTypes.object,
    formFields: PropTypes.array.isRequired,
    conflictedPatient: PropTypes.object,
    patientCancelButtonVariant: PropTypes.string,
    errorConflictAlertVariant: PropTypes.string,
    formHeaderTitleTranslation: PropTypes.object,
    patientCancelButtonTranslation: PropTypes.object,
    patientSubmitButtonTranslation: PropTypes.object,
    errorGeneralMessageTranslation: PropTypes.object,
    isEditPatient: PropTypes.bool.isRequired,
    isReadOnlyForm: PropTypes.bool.isRequired,
    isPatientSubmitDisabled: PropTypes.bool,
    isErrorConflictAlert: PropTypes.bool.isRequired,
    isErrorConflictPopup: PropTypes.bool.isRequired,
    isErrorGeneralPopup: PropTypes.bool.isRequired,
    userClickedOnConfirmErrorGeneralPopup: PropTypes.func.isRequired,
    userClickedOnCancelPatientForm: PropTypes.func.isRequired,
    userClickedOnSubmitPatientForm: PropTypes.func.isRequired,
    userChangedInputPatient: PropTypes.func.isRequired,
    isGeneratedChartNumberRequired: PropTypes.bool,
    shouldHideGenderAndDob: PropTypes.bool
  };

  componentDidMount() {
    window.addEventListener('touchmove', this.handleScroll);
  }

  componentDidUpdate() {
    this.updateGeneratedChartNumber(this.props.formValues);
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.handleScroll);
  }

  getConflictPatientDetails = (dateOfBirthFormat, regulatoryDateOfBirthMask) => {
    const { conflictedPatient, shouldHideGenderAndDob } = this.props;
    if (!conflictedPatient || !conflictedPatient.fullName) {
      return '';
    }

    const isGender = Number.isInteger(conflictedPatient.gender);
    const dateOfBirth = conflictedPatient.dateOfBirth;
    const chartNumber = conflictedPatient.chartNumber;

    const msg = (
        <>
          {<TranslatedContent contentArray={[conflictedPatient.fullName.trim()]} />}
          {!shouldHideGenderAndDob && isGender && ', '}
          {!shouldHideGenderAndDob && isGender && <TranslatedContent contentArray={[conflictedPatient.gender ? messages.female : messages.male]} />}
          {!shouldHideGenderAndDob && dateOfBirth && ', '}
          {!shouldHideGenderAndDob && dateOfBirth && utils.formatDateOfBirth(dateOfBirth, dateOfBirthFormat, regulatoryDateOfBirthMask)}
          {chartNumber && ', '}
          {chartNumber && <TranslatedContent contentArray={[conflictedPatient.chartNumber]} />}
        </>
    );
    return msg;
  };

  updateGeneratedChartNumber = (formValues) => {
    if (this.props.isGeneratedChartNumberRequired) {
      const generatedChartNumber = (formValues.firstName ? formValues.firstName[0] : '')
          + (formValues.lastName ? formValues.lastName[0] : '')
          + initialChartNumber;
      this.props.change(FieldName.CHART_NUMBER, generatedChartNumber);
    }
  };

  handleChange = debounce((e, isKeyField) => {
    const { formValues, companyId, isEditPatient, formLastInvalid, invalid, userChangedInputPatient } = this.props;
    this.updateGeneratedChartNumber(formValues);
    userChangedInputPatient({ form: formValues, companyId, isEditPatient, isKeyField, formLastInvalid, invalid });
  }, 300);

  forceDateOfBirthChange = (value) => {
    this.props.change(FieldName.DATE_OF_BIRTH, value);
  }

  handleFormSubmit = e => {
    const { formValues, patientId, companyId, conflictedPatient, userClickedOnSubmitPatientForm } = this.props;
    e.preventDefault();
    userClickedOnSubmitPatientForm({ form: formValues, patientId, companyId, conflictedPatient });
  };

  handleScroll = e => {
    document.activeElement.blur();
  };

  render() {
    const {
      submitting,
      invalid,
      formFields,
      conflictedPatient,
      patientCancelButtonVariant,
      errorConflictAlertVariant,
      isEditPatient,
      isReadOnlyForm,
      isPatientSubmitDisabled,
      isErrorConflictAlert,
      isErrorGeneralPopup,
      formHeaderTitleTranslation,
      patientCancelButtonTranslation,
      patientSubmitButtonTranslation,
      errorGeneralMessageTranslation,
      userClickedOnCancelPatientForm,
      userClickedOnConfirmErrorGeneralPopup,
      dateOfBirthFormat,
      regulatoryDateOfBirthMask,
      shouldHideGenderAndDob
    } = this.props;

    const footerActions = (
        <>
          <BaseButton
              id="btn-cancel"
              className={styles.cancelButton}
              onClick={userClickedOnCancelPatientForm}
              color={patientCancelButtonVariant}
              size="large"
          >
            <TranslatedContent contentArray={[patientCancelButtonTranslation]} />
          </BaseButton>
          {!isReadOnlyForm && (
              <BaseButton
                  id="btn-submit"
                  className={styles.submitButton}
                  disabled={invalid || submitting || isPatientSubmitDisabled}
                  type="submit"
                  color="secondary"
                  size="large"
              >
                <TranslatedContent contentArray={[patientSubmitButtonTranslation]} />
              </BaseButton>
          )}
        </>
    );

    const formContainerClassName = classNames(styles.formContainer, {
      [styles.formContainerDisplayColumn]: shouldHideGenderAndDob
    });

    return (
        <>
          <form onSubmit={this.handleFormSubmit} onScroll={this.handleScroll}>
            <Header title={<TranslatedContent contentArray={[formHeaderTitleTranslation]} />} />
            <div className={formContainerClassName}>
              {formFields.map(
                  field => {
                    const fieldProps = {
                      conflictedPatient,
                      readOnly: isReadOnlyForm || field.isDisabled,
                      canShowErrorOnLoad: isEditPatient,
                      maxLength: (field.name === FieldName.LAST_NAME) ? this.props.maxPatientLastNameLength : '',
                      format: (field.name === FieldName.DATE_OF_BIRTH) ? dateOfBirthFormat : ''
                    };

                    const shouldHide = shouldHideGenderAndDob && (field.name === FieldName.DATE_OF_BIRTH || field.name === FieldName.GENDER)

                    return field.isVisible && !shouldHide && (
                        <div
                            className={styles.inputFieldColumn}
                            key={field.name}
                        >
                            <Field
                                key={field.name}
                                name={field.name}
                                component={DynamicInputField}
                                validate={field.validations}
                                formFields={field}
                                props={fieldProps}
                                onChange={e => this.handleChange(e, field.isKeyField)}
                                forceDateOfBirthChange={this.forceDateOfBirthChange}
                                normalize={field.normalize}
                            />

                        </div>
                    );
                  }
              )}
            </div>

            <div className={styles.alert}>
              {isErrorConflictAlert && conflictedPatient && !isReadOnlyForm && (
                  <Alert
                      variant={errorConflictAlertVariant}
                      heading={<TranslatedContent contentArray={[messages.conflictHeader]} />}
                      content={this.getConflictPatientDetails(dateOfBirthFormat, regulatoryDateOfBirthMask)}
                  />
              )}
            </div>

          <Footer className={styles.footer} actions={footerActions} />
        </form>
        <WarningPopup
          open={!!isErrorGeneralPopup}
          handleConfirmClick={userClickedOnConfirmErrorGeneralPopup}
          confirmText={<TranslatedContent contentArray={[messages.buttonOk]} />}
          title={<TranslatedContent contentArray={[messages.errorGeneralHeader]} />}
          message={<TranslatedContent contentArray={[errorGeneralMessageTranslation]} />}
        />
      </>
    );
  }
}

const formName = 'PatientForm';

const initialChartNumber = Math.floor((new Date().valueOf() / 1000 / 60) % 1000000).toString();

const mapStateToProps = state => {
  const { patientManagement, shell } = state;
  const maxPatientLastNameLength = (shell.config.regulatorySettings && shell.config.regulatorySettings.maxPatientLastNameLength)
      ? shell.config.regulatorySettings.maxPatientLastNameLength : '';

  return {
    patientId: shell.fromUrl.patientId,
    companyId: shell.fromUrl.companyId,
    patientType: shell.fromUrl.patientType,
    config: shell.config,
    initialValues: patientManagement.initialPatientData,
    formValues: getFormValues(formName)(state),
    formFields: patientManagement.formFields,
    conflictedPatient: patientManagement.conflictedPatient,
    patientCancelButtonVariant: patientManagement.patientCancelButtonVariant,
    errorConflictAlertVariant: patientManagement.errorConflictAlertVariant,
    formHeaderTitleTranslation: patientManagement.formHeaderTitleTranslation,
    patientCancelButtonTranslation: patientManagement.patientCancelButtonTranslation,
    patientSubmitButtonTranslation: patientManagement.patientSubmitButtonTranslation,
    errorGeneralMessageTranslation: patientManagement.errorGeneralMessageTranslation,
    formLastInvalid: patientManagement.formLastInvalid,
    isEditPatient: patientManagement.isEditPatient,
    isReadOnlyForm: patientManagement.isReadOnlyForm,
    isPatientSubmitDisabled: patientManagement.isPatientSubmitDisabled,
    isErrorConflictAlert: patientManagement.isErrorConflictAlert,
    isErrorConflictPopup: patientManagement.isErrorConflictPopup,
    isErrorGeneralPopup: patientManagement.isErrorGeneralPopup,
    isGeneratedChartNumberRequired: patientManagement.isGeneratedChartNumberRequired,
    maxPatientLastNameLength,
    dateOfBirthFormat: shell.fromUrl.dateOfBirthFormat ? shell.fromUrl.dateOfBirthFormat :
                        shell.config.regulatorySettings ? shell.config.regulatorySettings.dobMask : fullDateOfBirthMask,
    regulatoryDateOfBirthMask: shell.config.regulatorySettings ? shell.config.regulatorySettings.dobMask : undefined,
    shouldHideGenderAndDob: shell.fromUrl.shouldHideGenderAndDob
  };
};

const mapDispatchToProps = {
  userClickedOnConfirmErrorGeneralPopup,
  userClickedOnCancelPatientForm,
  userClickedOnSubmitPatientForm,
  userChangedInputPatient
};

PatientForm = reduxForm({
  form: formName,
  touchOnBlur: true,
  touchOnChange: true,
  enableReinitialize: true
})(PatientForm);

PatientForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientForm);

export default PatientForm;
